@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.glassmorphism {
  background: rgba(255, 255, 255, 0.12);
  /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 0.8px solid rgba(255, 255, 255, 0.18);
}
.glassmorphism-chat {
  background: rgba(255, 255, 255, 0.12);
  /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 0.8px solid rgba(255, 255, 255, 0.18);
}

.glassmorphism-chat-blue {
  background: rgba(66, 186, 242, 0.3);
  /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */
  backdrop-filter: blur(6.5px);
  -webkit-backdrop-filter: blur(6.5px);
  border: 1px solid rgba(255, 255, 255, 0.18);
}
.glassmorphism-green {
  background: rgba(66, 242, 148, 0.3);
  /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */
  backdrop-filter: blur(6.5px);
  -webkit-backdrop-filter: blur(6.5px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  opacity: 0.8;
}

/* CSS */
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  border: 4px solid rgba(143, 38, 38, 0.1);
  border-left: 4px solid #ff0000;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

/* Animated Gradient */
.animated-gradient {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    16deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(38, 25, 115, 1) 52%,
    rgba(25, 94, 115, 1) 99%
  );
  background-size: 400% 400%;
  animation: gradient 5s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
